// Fix for Roboto font path
$roboto-font-path: '../assets/';

//ORIGINALES
//$byta-green: #6ca73c;
//$byta-grey1: #737068;

//PRUEBAS
$grey-background: #EDEDED;
$grey-background-light: #F5F5F5;
$grey-background-dark: #CCCCCC;
$grey-background-band: #EDEDED;
$grey-background-footer: #9D9D9C;
$grey-background-footer-band: #B2B2B2;
$grey-band: #AAAAAA;
$grey-letters: #575756;

$green: #99cc33;
$green-letters: #99cc33;
$green-background: #99cc33;

$red-required: #e91d1d;

$font-style: 'Open Sans', sans-serif;
$font-light: 0.9em;
$font-normal: 1.2em;
$font-subtitle: 2em;
$font-title: 3em;
$font-light-medium: 0.8em;
$font-normal-medium: 1.1em;
$font-subtitle-medium: 1.9em;
$font-title-medium: 2.2em;
$font-light-small: 0.7em;
$font-normal-small: 1em;
$font-subtitle-small: 1.2em;
$font-title-small: 1.5em;
$font-gallery: 1.2em;
$font-thin: thin;
$font-italic: italic;
$font-bold: bold;
$text-align: justify;
$text-justify: inter-word;
$text-center: center;

// Materialize styles
// @import 'components/color';
// @import 'components/variables';
// @import 'materialize';

.body {
	background-color: $grey-background-light;
	overflow: auto !important;
}
.byta-img-background{
	background: url("img/contact_01.jpg");
	opacity: 0.9;
}

.byta-img-home-background{
	background: url("img/home/home_09.jpg");
	opacity: 0.9;
}

.byta-img-staging-background{
	background: url("img/staging/Home_Staging_04.jpg");
	max-width: 100%;
}

.byta-img-staging-statistics-background{
	background: url("img/staging/Home_Staging_02.jpg");
	opacity: 0.9;
}

.byta-img-other-services-01-background{
	background: url("img/services/other_services_02.jpg");
	max-width: 100%;
  height: auto;
}

.byta-img-other-services-02-background{
	background: url("img/services/other_services_03.jpg");
	opacity: 0.9;
	width: 100%;
}

.byta-img-contact-background{
	background: url("img/contact/contact_02.jpg");
	background-repeat: no-repeat;
  background-size: cover;
	background-position: center;
}

.byta-img-contact-card-background{
	background: url("/assets/svg/contact_01.svg");
	background-repeat: no-repeat;
  background-size: cover;
	background-position: center;

}


//General

.hidden {
    display: none !important;
    visibility: hidden;
}

.col-custom-materialbox {
    width: auto;
    margin-left: auto;
    left: auto;
    right: auto;
    float: left;
    box-sizing: border-box;
    padding-top: 0px;
    padding-right: 0.5rem;
    padding-bottom: 0px;
    padding-left: 0.5rem;
    min-height: 1px;
}
.row-materialbox {display: flex;}

.wave-image {
	height: 225px;
}



//Colors

.byta-white-text {
	color: white !important;
	font-family: $font-style;
}

.byta-white-bg {
	background-color: white !important;
}
.byta-green-bg {
	background-color: $green !important;
}

.byta-grey-bg {
	background-color: $grey-background !important;
	height: auto;
 }

.grey-background-light {
	background-color: $grey-background-light !important;
	height: auto;
}

.byta-bg-footer {
	background-color: $grey-background-footer !important;
}

.byta-bg-footer-band {
	background-color: $grey-background-footer-band !important;
}

.byta-grey-text-footer {
	color: $grey-letters !important;
}
.byta-grey-bg-dark {
	background-color: $grey-background-dark !important;
}

.byta-grey-bg-band {
 background-color: $grey-background-band !important;
}

.byta-green-bg {
 	background-color: $green-background !important;
}

.mce_inline_error {
 	color: $red-required !important;
	background-color: transparent !important;
	font-size: $font-light !important;
	font-weight: normal !important;
}

input.mce_inline_error {
 	border-color: $red-required !important;
}

.byta-green-text {
	color: $green !important;
	font-family: $font-style;
}

.byta-grey-text {
	color: $grey-letters !important;
	font-family: $font-style;
 }

 .byta-grey-text-light {
 	color: $grey-letters !important;
 	font-family: $font-style;
 	font-size: $font-light;
}

.byta-grey-text-gallery {
	color: $grey-letters !important;
	font-family: $font-style;
	font-size: $font-gallery;
 }

.byta-bold-text{
	font-weight: $font-bold
}

.byta-underline-text{
	text-decoration-line: underline;
}

.byta-text-light {
	font-size: $font-light;
}

.byta-text-normal {
	font-size: $font-normal;
}

.byta-text-title {
	font-size: $font-title;
}

.byta-text-subtitle {
	font-size: $font-subtitle;
}

.byta-grey-text-simple {
	font-family: $font-style;
	color: $grey-letters !important;
}

.byta-italic-text{
	font-style: $font-italic
}

.byta-grey-subtitle {
	background-color: rgba(170,170,170,.7);
	padding-top: 0.5em;
	padding-bottom: 0.5em;
}

.byta-justify{
	text-align: $text-align;
}

.byta-center{
	text-align: $text-center;
}

.nav-text-item {
	font-weight: bold;
	color: $grey-letters
}

.side-menu-logo {
	padding: 0 50px;
}

.card-content {
	max-height: 50% !important;
}

.no-padding {
	padding: 0 !important;
}

.no-margin {
	margin: 0 !important;
}

.height {
	height: 100% !important;
}

.sidenav-large {
	position: fixed;
	top: 0;
	height: 100vh;
	z-index: 999 !important;
}

.sidenav-large-item {
	width: 100%;
	display: block;
	height: 48px;
	line-height: 48px;
	padding: 0 32px;
	color: $grey-letters;
}

.sidenav-large-header {
	width: 100%;
}

.sidenav-large-list {
	padding-left: 25px !important;
}

.sidenav-large-list li {
	height: 50px !important;
	margin-bottom: 10px;
}

.sidenav-large-title {
	height: 40px !important;
}

.footer-padding {
	padding: 4vh !important;
}

.margin-vertical {
	margin-top: 20px;
	margin-bottom: 20px;
}

.padding-1em {
	padding-left:1em;
	padding-right:1em;
	padding-top:1em;
	padding-bottom:1em;
}

.padding-text{
	margin-left: 1em;
	margin-right: 1em
}

.padding-button{
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 1em;
    padding-bottom: 1em;
}

.padding-menu{
	padding-top: 2em;
}

.img-top-padding {
	padding: 2em !important;
}

.img-text{
  position: absolute;
  max-width: 100%;
	top: auto;
	width: 100%;
	bottom: 0px;
	text-align: center;
}

.img-title{
	position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 0% 0%;
  z-index: 10;
	font-family: $font-style;
}

.img-btn-search{
	left: 45% !important;
	top: 50% !important;
}
.img-btn-search-image{
	left: 45% !important;
	top: 40% !important;
}

// .img-btn-see-more{
// 	position: absolute;
// 	left: 50% !important;
// 	margin-right: -50%;
// 	transform: translate(-50%);
// 	opacity: 1 !important;
// }

.img-text-block-right {
	position: absolute;
	top: 1%;
	right: 1%;
	transform: translate(-10%, 15%);
	background-color: rgba(#AAAAAA, 0.8);
	color: white;
	padding-left: 1%;
	padding-right: 1%;
	padding-bottom: 2%;
	padding-top: 1%;
	width: 50%;
}

.img-text-block-left {
	position: absolute;
	top: 5%;
	left: 5%;
	width: 50%;
	margin-right: -50%;
	background-color: rgba(#AAAAAA, 0.8);
	color: white;
	padding-left: 1%;
	padding-right: 1%;
	padding-bottom: 2%;
	padding-top: 1%;
}

.img-container {
    position: relative;
		img {
			display: block;
		}
}


.background-transparent {
	background-color: transparent !important;
}

//min-margins
.min-height-320{
	min-height: 270px;
}

.min-height-400{
	min-height: 350px;
}
// flex
.flex-box { // clase para las filas
	display: flex;
}
.flex-full { // clase para la columna
	flex: 1;
}
.modal-box-content{
	display: flex;
	align-content: stretch
}

// botón flotante
.fixed-action-btn {
	right: 0;
	bottom: 0;
	transform: translate(-40%, -40%);
}

// reset
button {
	border: 0 none;
}

.flow-text {
	font-size: $font-normal;
}

.border-button {
	border-radius: 0.5em;
}

.sidenav-large-list .active{
	color: $grey-letters !important;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
	background-image: url("data:image/svg+xml;charset=utf-8,
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'>
		<path d='M0,22L22,0l2.1,2.1L4.2,22l19.9,19.9L22,44L0,22L0,22L0,22z' fill='#ffffff'/>
	</svg>");
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
	background-image: url("data:image/svg+xml;charset=utf-8,
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'>
		<path d='M27,22L27,22L5,44l-2.1-2.1L22.8,22L2.9,2.1L5,0L27,22L27,22z' fill='#ffffff'/>
	</svg>");
  right: 10px;
  left: auto;
}


@media only screen and (max-width: 600px) {

	.byta-grey-subtitle {
		 font-size: $font-subtitle-small !important;
	}

	.byta-text-big {
		font-size: 20px !important;
	}


	.img-btn-see-more{
		// transform: translate(-50%, -30%);
		display: block;
	}

	.img-text-block-left {
		width: 100% !important;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.img-text-block-right {
		width: 100% !important;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.min-height-320{
		min-height: auto !important;
	}
	.min-height-400{
		min-height: auto !important;
	}

	.flow-text {
		font-size: $font-normal-small !important;
	}
	.byta-grey-text-light {
	 	font-size: $font-light-small !important;
	}
	.byta-text-normal {
		font-size: $font-normal-small !important;
	}

	.byta-text-title {
		font-size: $font-title-small !important;
	}

	.byta-text-subtitle {
		font-size: $font-subtitle-small !important;
	}
	.modal-box-content{
		flex-direction: column;
	}

}

@media only screen and (max-width: 992px) {

	.img-text{
		top: auto;
		bottom: 0px;
	}
	.min-height-320{
		min-height: 280px;
	}
	.min-height-400{
		min-height: 290px;
	}

	.byta-grey-text-light {
		font-size: $font-light-medium;
	}
	.byta-text-normal {
		font-size: $font-normal-medium;
	}

	.byta-text-title {
		font-size: $font-title-medium;
	}

	.byta-text-subtitle {
		font-size: $font-subtitle-medium;
	}

	.flow-text {
		font-size: $font-normal-medium;
	}
}
